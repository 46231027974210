import { CheckboxComponent, SearchFieldComponent } from '@abm/controls';
import { afterNextRender, ChangeDetectionStrategy, Component, inject, model, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@lf/translate-core';
import { SIDE_SETTINGS_CONFIG_LIST } from '@tokens/side-settings-config.token';
import { TreeNode } from '@type/tree/tree.type';
import { SearchPipe } from 'src/app/core/pipe/search/search.pipe';

@Component({
  selector: 'app-strategy-row-visibility-settings',
  imports: [SearchFieldComponent, TranslatePipe, CheckboxComponent, ReactiveFormsModule, FormsModule, SearchPipe],
  templateUrl: './strategy-row-visibility-settings.component.html',
  styleUrl: './strategy-row-visibility-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategyRowVisibilitySettingsComponent<T> {
  #data = inject(SIDE_SETTINGS_CONFIG_LIST)[0];

  get row() {
    return this.#data.value.data as TreeNode<T>;
  }

  get form() {
    return this.#data.value.form;
  }

  query = model('');

  checkedCount = signal(0);

  constructor() {
    afterNextRender(() => this.checkedCount.set(this.form.controls.filter(({ value: { checked } }) => checked).length));
  }

  computeSelectedCount(event: boolean) {
    this.checkedCount.update((c) => (event ? ++c : --c));
  }
}
