<header>
  <h4 class="lf-subtitle">{{ row.name }}</h4>
  <div class="select-info">{{ checkedCount() }}/{{ form.controls.length }}</div>
</header>

<abm-search-field
  [placeholder]="'placeholder.search' | t"
  [(ngModel)]="query"
  [withLabel]="false" />

<div class="form">
  @for (control of form.controls | search: query() : 'value' : 'name'; track $index) {
    <abm-checkbox
      [(ngModel)]="control.value.checked"
      [disabled]="control.value.disabled"
      (ngModelChange)="computeSelectedCount($event)"
      >{{ control.value.name }}</abm-checkbox
    >
  }
</div>
