import { EnvironmentInjector, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormArray, FormControl } from '@angular/forms';
import { SideSettingsComponent } from '@modules/components/side-settings/side-settings.component';
import { StrategyFieldValue, StrategyMatrixValue } from '@type/strategy.type';
import { TreeNode } from '@type/tree/tree.type';
import { distinctUntilChanged, filter, firstValueFrom, Subject, tap } from 'rxjs';
import { StrategyRowVisibilitySettingsComponent } from '../../components/strategy-row-visibility-settings/strategy-row-visibility-settings.component';
import { createFieldName } from '../../utils/functions';
import { StrategyApiService } from '../strategy-api/strategy-api.service';
import { StrategyStateService } from '../strategy-state/strategy-state.service';
import { StrategyTreeService } from '../strategy-tree/strategy-tree.service';

@Injectable()
export class StrategyRowVisibilityService {
  #ei = inject(EnvironmentInjector);

  #settingsChanges = new Subject<{ checked: boolean; name: string; prop: StrategyFieldValue }[]>();

  #tempRow = signal<TreeNode<StrategyMatrixValue> | null>(null);

  #api = inject(StrategyApiService);

  #state = inject(StrategyStateService);

  #treeService = inject(StrategyTreeService);

  #rowChanges = toSignal(
    this.#settingsChanges.pipe(
      filter(() => !!this.#tempRow()),
      distinctUntilChanged(),
      tap((changes) => {
        const cache = this.#getCache(this.#tempRow());
        if (cache) {
          const result = changes.filter(({ checked }) => checked).map(({ prop: { id } }) => id);
          cache.presentValues = result ?? cache.presentValues;
          !!result && this.#tempRow()?.replaceChildren();
        }
      }),
    ),
  );

  async openSettings(row: TreeNode<StrategyMatrixValue>) {
    this.#tempRow.set(row);

    const strategy = this.#state.strategy();
    if (strategy) {
      const props = await firstValueFrom(this.#api.getAvailableFieldValues(strategy.id, row.getIdList()));

      SideSettingsComponent.showSettings(this.#ei, [
        {
          name: 'row_settings.title',
          value: {
            form: new FormArray(
              props.map((p) => {
                const child = row.children?.find(({ id }) => id === p.id);
                return new FormControl({
                  prop: p,
                  checked: !!child,
                  disabled: !!child?.isFilled(),
                  name: createFieldName(p, this.#treeService.translations),
                });
              }),
            ),
            header: 'row_settings.title',
            component: StrategyRowVisibilitySettingsComponent<StrategyMatrixValue>,
            okButtonIcon: 'success',
            okButtonText: 'button.apply',
            cancelButtonText: 'button.cancel',
            showCheckbox: true,
            maxSelected: 0,
            resultData: this.#settingsChanges,
            data: row,
          },
        },
      ]);
    }
  }

  #getCache<T>(row: TreeNode<T> | null) {
    return row ? this.#state.getMatrixFromCache(row.getIdList() || []) : null;
  }
}
