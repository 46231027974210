import { Injectable } from '@angular/core';
import { Operator } from '@enums/operator';
import { Limit } from '@type/limits';
import { MatrixCellCount } from '@type/strategy.type';
import { StrategyBodyCellComponent } from '../../components/strategy-body-cell/strategy-body-cell.component';

@Injectable()
export class TableProcessService {
  rewriteCells(cell: StrategyBodyCellComponent) {
    const changes = this.getValueChanges(cell);

    if (cell.matrixValue) {
      this.applyLimits(cell, cell.matrixValue.value());
      this.setPreviousValue(cell);

      if (changes) {
        this.setStrategyChanges(cell);
        this.processParentRows(cell, changes);
      }
    }
  }

  private processParentRows({ row, item }: StrategyBodyCellComponent, changes: MatrixCellCount) {
    let r = row();
    const id = item().id;

    while (r.parent && !!r.parent.depth) {
      r = r.parent;
      const parentCell = r.data[id];
      parentCell.value.update((v) => [v[0] + changes.min, v[1] + changes.max]);
      {
        if (r.expandable) {
          parentCell.innerDeviation.set([
            !!r.children?.some((cr) => cr.data[id].innerDeviation()[0] || cr.data[id].deviated()[0]),
            !!r.children?.some((cr) => cr.data[id].innerDeviation()[1] || cr.data[id].deviated()[1]),
          ]);
        }
      }
    }

    this.updateCapacity(item, changes);
  }

  private updateCapacity(item: StrategyBodyCellComponent['item'], changes: MatrixCellCount) {
    let item2 = item();
    item2 = { ...item2, current_quantity: (item2.current_quantity += changes.max) };
  }

  private setStrategyChanges(cmp: StrategyBodyCellComponent) {
    cmp.state.setStrategyChanges(cmp);
  }

  private getValueChanges({ matrixValue, prevCount }: StrategyBodyCellComponent) {
    if (matrixValue) {
      return { min: matrixValue.value()[0] - prevCount.min, max: matrixValue.value()[1] - prevCount.max };
    } else {
      return { min: 0, max: 0 };
    }
  }

  private setPreviousValue(cell: StrategyBodyCellComponent) {
    cell.prevCount = { min: cell.matrixValue?.value()[0] || 0, max: cell.matrixValue?.value()[1] || 0 };
  }

  private applyLimits(cell: StrategyBodyCellComponent, newValue: [number, number]) {
    cell.attachedLimits ??= this.getAttachedLimits(cell);
    cell.deviatedLimits = this.getDeviatedLimits(cell, newValue);
    cell.matrixValue?.deviated.set([!!cell.deviatedLimits[0].length, !!cell.deviatedLimits[1].length]);
  }

  private getDeviatedLimits(
    { matrixValue, strategy, attachedLimits }: StrategyBodyCellComponent,
    value: [number, number],
  ): [Limit[], Limit[]] {
    if (attachedLimits) {
      return [
        attachedLimits[0].filter((l) => Function(`return ${value[0]}${this.invertOperator(l.operator)}${l.value};`)()),
        attachedLimits[1].filter((l) => Function(`return ${value[1]}${this.invertOperator(l.operator)}${l.value};`)()),
      ];
    }
    return [
      strategy()?.strategy_limits.filter(({ id }) => matrixValue?.deviatedLimitIds[0].includes(id)) || [],
      strategy()?.strategy_limits.filter(({ id }) => matrixValue?.deviatedLimitIds[1].includes(id)) || [],
    ];
  }

  private getAttachedLimits({ matrixValue, strategy }: StrategyBodyCellComponent): [Limit[], Limit[]] {
    return [
      strategy()?.strategy_limits.filter(
        ({ id }) => matrixValue?.attachedLimitIds?.includes(id) || matrixValue?.deviatedLimitIds?.[0]?.includes(id),
      ) || [],
      strategy()?.strategy_limits.filter(
        ({ id }) => matrixValue?.attachedLimitIds?.includes(id) || matrixValue?.deviatedLimitIds?.[1]?.includes(id),
      ) || [],
    ];
  }

  private invertOperator(operator: Operator) {
    switch (operator) {
      case Operator['=']:
        return Operator['!='];
      case Operator['!=']:
        return '==';
      case Operator['<']:
        return Operator['>='];
      case Operator['>']:
        return Operator['<='];
      case Operator['<=']:
        return Operator['>'];
      case Operator['>=']:
        return Operator['<'];
      default:
        return '==';
    }
  }
}
