import { signal } from '@angular/core';
import { StrategyMatrixValueItem } from '@type/strategy.type';
import { sortArrayByAnother } from '@utils/sort-array';

export type ExpandChangeEvent<DataType> = {
  data: DataType;
  isExpanded: boolean;
};

export type BaseTreeType<DataType> = {
  id: number | string;
  name: string;
  data: DataType;
  index: number;
  children?: Array<BaseTreeType<DataType>> | undefined;
};

export class TreeNode<DataType> {
  isExpanded = signal(false);

  rewriteChilds = signal<number | null>(null);

  id: number | string;

  name: string;

  expandable: boolean;

  collapsible: boolean;

  depth: number;

  data: DataType;

  parent?: TreeNode<DataType> | undefined;

  children?: TreeNode<DataType>[] | undefined;

  index: number = 0;

  maxOpenedLevel = 1;

  constructor(
    node: BaseTreeType<DataType>,
    parent: TreeNode<DataType> | undefined,
    depth = 1,
    index = 0,
    expanded = false,
  ) {
    this.index = node.index;
    this.id = node.id || index;
    this.name = node.name;
    this.depth = depth;
    this.expandable = Boolean(node.children);
    this.data = node.data;
    this.parent = parent;
    this.collapsible = Boolean(parent) && this.expandable;
    this.children =
      this.expandable ? node.children?.map((n, i) => new TreeNode(n, this, this.depth + 1, n.index)) : undefined;
    this.isExpanded.set(expanded);
  }

  patch(callback: (data: DataType) => void, withChildren: boolean = false) {
    callback(this.data);

    if (withChildren) {
      this.children?.forEach((c) => c.patch(callback, withChildren));
    }
  }

  isFilled() {
    for (const key in this.data) {
      if ((this.data[key] as StrategyMatrixValueItem).value().toString() != '0,0') return true;
    }
    return false;
  }

  expand() {
    this.isExpanded.set(true);
    this.maxOpenedLevel = this.depth;
  }

  collapse(withChildren: boolean = false) {
    this.isExpanded.set(false);

    if (withChildren) {
      this.children?.forEach((c) => {
        c.collapse(withChildren);
        c.maxOpenedLevel = this.depth;
      });
    }

    this.maxOpenedLevel = this.depth;
  }

  getIdList() {
    const list = !!Number(this.id) ? [Number(this.id)] : [];
    let node: TreeNode<DataType> = this;

    while (node.parent) {
      !!node.parent.id && list.push(Number(node.parent.id));
      node = node.parent;
    }

    return list.reverse();
  }

  addChildren(children: TreeNode<DataType>[]) {
    if (this.children) {
      const map = new Map(children.map((c) => [c.id, c]));
      this.children.forEach((c) => c.isFilled() && map.set(c.id, c));
      this.children = sortArrayByAnother(Array.from(map.values()), this.children);
    } else this.children = children;
  }

  replaceChildren() {
    this.rewriteChilds.set(Date.now());
  }

  allCollapsedOnCurrentDepth() {
    return !!this.parent && !!this.parent?.children?.every((c) => !c.isExpanded());
  }

  async *async(chunkSize: number = 30) {
    if (this.children) {
      for (let i = 0; i < this.children.length; i += chunkSize) {
        yield await Promise.resolve([...this.children].slice(i, i + chunkSize));
      }
    }
  }
}

export class Tree<DataType> {
  root: TreeNode<DataType> = new TreeNode(
    {
      index: 0,
      id: '',
      name: '',
      data: {} as DataType,
      children: [],
    },
    undefined,
    0,
  );

  constructor(nodeList: BaseTreeType<DataType>[], rootName: string) {
    this.root.name = rootName;
    this.root.children = nodeList.map((e) => new TreeNode<DataType>(e, this.root));
  }
}
