import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@lf/translate-core';
import {
  ConvertMatrixOptions,
  StrategyFieldValue,
  StrategyItem,
  StrategyMatrix,
  StrategyMatrixValue,
  StrategyMatrixWithPresentValues,
} from '@type/strategy.type';
import { BaseTreeType, Tree, TreeNode } from '@type/tree/tree.type';
import { convertMatrixToData, createFieldName } from '../../utils/functions';

@Injectable()
export class StrategyTreeService {
  private translate = inject(TranslateService);

  private tree: Tree<StrategyMatrixValue> | null = null;

  private maxTreeDepth = 0;

  translations = {
    yes: this.translate.t('true'),
    no: this.translate.t('false'),
    empty: this.translate.t('empty'),
  };

  createTree = (
    initialMatrixConfig: StrategyMatrixWithPresentValues,
    strategy: StrategyItem,
    availableFieldValues: StrategyFieldValue[],
    rootName: string,
  ) => {
    this.maxTreeDepth = (strategy.strategy_fields.length || 1) - 1;

    this.tree = new Tree<StrategyMatrixValue>(
      this.convertStrategyMatrixToTree<StrategyMatrixValue>({ ...initialMatrixConfig, availableFieldValues }, strategy),
      rootName,
    );

    return this.tree;
  };

  convertStrategyMatrixToTree<T>(
    opts: ConvertMatrixOptions<T>,
    strategy: StrategyItem | null,
    level = 0,
  ): TreeNode<T>[] {
    if (!(opts && strategy)) return [];

    const mapToBaseTreeType = (level: number) => {
      let data: BaseTreeType<T>[];
      data ??= [];

      let prepared = opts.availableFieldValues
        .filter(({ id }) => (!!opts.presentValues.length ? opts.presentValues.includes(id) : false))
        .map((fv, i) => this.convertFieldValueToBaseTreeType<T>(fv, opts.matrix!, level, i));

      data.push(...prepared);
      return data;
    };

    const data = mapToBaseTreeType(level);

    return data.map((el) => new TreeNode(el, opts.branch, level + 1));
  }

  convertFieldValueToBaseTreeType<T>(
    fieldValue: StrategyFieldValue,
    matrix: StrategyMatrix,
    level: number,
    index: number,
  ) {
    return {
      id: fieldValue.id,
      name: createFieldName(fieldValue, this.translations),
      data: convertMatrixToData(matrix, fieldValue.id),
      children: this.initChildren(level),
      index,
    } as BaseTreeType<T>;
  }

  private initChildren(currentDepth: number) {
    return currentDepth < this.maxTreeDepth ? [] : undefined;
  }
}
